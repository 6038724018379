export const environmentalCommonFormulations: Record<string, Record<string, number>> = {
  Custom: {
    Dog: 0,
    Cat: 0,
    'Dust Mite Mix': 0,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0,
    Cottonwood: 0,
    Oak: 0,
    'Black Walnut': 0,
    Cedar: 0,
    'Maple Box Elder': 0,
    'White Ash': 0,
    Birch: 0,
    'Lambs Quarters': 0,
    'Short Ragweed': 0,
    Bermuda: 0,
    Orchard: 0,
  },
  '#1 Pets HDM': {
    Dog: 1.0,
    Cat: 1.0,
    'Dust Mite Mix': 1.0,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0,
    Cottonwood: 0,
    Oak: 0,
    'Black Walnut': 0,
    Cedar: 0,
    'Maple Box Elder': 0,
    'White Ash': 0,
    Birch: 0,
    'Lambs Quarters': 0,
    'Short Ragweed': 0,
    Bermuda: 0,
    Orchard: 0,
  },
  '#2 Pets': {
    Dog: 1.5,
    Cat: 1.5,
    'Dust Mite Mix': 0,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0,
    Cottonwood: 0,
    Oak: 0,
    'Black Walnut': 0,
    Cedar: 0,
    'Maple Box Elder': 0,
    'White Ash': 0,
    Birch: 0,
    'Lambs Quarters': 0,
    'Short Ragweed': 0,
    Bermuda: 0,
    Orchard: 0,
  },
  '#3 NE Mix Forest': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0.2,
    'American Elm': 0.2,
    Cottonwood: 0.2,
    Oak: 0.2,
    'Black Walnut': 0,
    Cedar: 0,
    'Maple Box Elder': 0.2,
    'White Ash': 0.2,
    Birch: 0.2,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0,
    Orchard: 0.4,
  },
  '#4 Mid-Atlantic': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0.2,
    Cottonwood: 0.2,
    Oak: 0.2,
    'Black Walnut': 0,
    Cedar: 0.4,
    'Maple Box Elder': 0.2,
    'White Ash': 0,
    Birch: 0.2,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0.2,
    Orchard: 0.4,
  },
  '#5 E Mountains ': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0.2,
    'American Elm': 0.2,
    Cottonwood: 0.2,
    Oak: 0.2,
    'Black Walnut': 0,
    Cedar: 0.2,
    'Maple Box Elder': 0.2,
    'White Ash': 0.2,
    Birch: 0.2,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0,
    Orchard: 0.4,
  },
  '#6 SE Coastal Plain': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0,
    Cottonwood: 0.2,
    Oak: 0.2,
    Bayberry: 0.2,
    'Black Walnut': 0.2,
    Cedar: 0,
    'Maple Box Elder': 0.2,
    'White Ash': 0,
    Birch: 0.2,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0.4,
    Orchard: 0.4,
  },
  '#7 Subtropical FL': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0,
    Cottonwood: 0,
    Oak: 0.2,
    'Black Walnut': 0.2,
    Cedar: 0,
    'Maple Box Elder': 0,
    'White Ash': 0.2,
    Bayberry: 0.2,
    Birch: 0,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0.4,
    Orchard: 0.4,
  },
  '#8 MW MS Valley': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0.2,
    Cottonwood: 0.2,
    Oak: 0.2,
    'Black Walnut': 0.2,
    Cedar: 0,
    'Maple Box Elder': 0.2,
    'White Ash': 0,
    Birch: 0.2,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0.2,
    Orchard: 0.4,
  },
  '#9 S Great Plains': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0.2,
    Cottonwood: 0,
    Oak: 0.2,
    'Black Walnut': 0.2,
    Cedar: 0.4,
    'Maple Box Elder': 0.2,
    'White Ash': 0,
    Birch: 0,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0.4,
    Orchard: 0.4,
  },
  '#10 Desert SW': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0,
    Cottonwood: 0.2,
    Oak: 0.2,
    'Black Walnut': 0,
    Cedar: 0.4,
    'Maple Box Elder': 0.2,
    'White Ash': 0.2,
    Birch: 0,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0.4,
    Orchard: 0.4,
  },
  '#11 Coastal CA': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0,
    Cottonwood: 0.2,
    Oak: 0.2,
    'Black Walnut': 0.2,
    Cedar: 0.4,
    'Maple Box Elder': 0.2,
    'White Ash': 0.2,
    Birch: 0,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0.2,
    Orchard: 0.4,
  },
  '#12 Pacific NW': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0,
    Cottonwood: 0.2,
    Oak: 0,
    'Black Walnut': 0,
    Cedar: 0.4,
    'Maple Box Elder': 0.2,
    'White Ash': 0.2,
    Birch: 0.2,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0,
    Orchard: 0.4,
  },
  '#13 Inter-Mountain': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0.2,
    Cottonwood: 0.2,
    Oak: 0,
    'Black Walnut': 0,
    Cedar: 0.4,
    'Maple Box Elder': 0.2,
    'White Ash': 0.2,
    Birch: 0.2,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0,
    Orchard: 0.4,
  },
  '#14 N Great Plains': {
    Dog: 0.5,
    Cat: 0.5,
    'Dust Mite Mix': 0.5,
    Alternaria: 0,
    'American Sycamore': 0,
    'American Elm': 0.2,
    Cottonwood: 0.2,
    Oak: 0,
    'Black Walnut': 0,
    Cedar: 0,
    'Maple Box Elder': 0.2,
    'White Ash': 0,
    Birch: 0.2,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.4,
    Bermuda: 0,
    Orchard: 0.4,
  },
  '#15 National': {
    Dog: 0.4,
    Cat: 0.4,
    'Dust Mite Mix': 0.4,
    Alternaria: 0.2,
    'American Sycamore': 0.2,
    'American Elm': 0.2,
    Cottonwood: 0.2,
    Oak: 0.2,
    'Black Walnut': 0.2,
    Cedar: 0.2,
    'Maple Box Elder': 0.2,
    'White Ash': 0.2,
    Birch: 0.2,
    'Lambs Quarters': 0.2,
    'Short Ragweed': 0.2,
    Bermuda: 0.2,
    Orchard: 0.2,
  },
};

export const foodCommonFormulations: Record<string, Record<string, number>> = {
  Custom: {
    'Fish Mix': 0,
    Codfish: 0,
    Flounder: 0,
    Mackerel: 0,
    Tuna: 0,

    'Nut Mix 1': 0,
    Almond: 0,
    'Brazil nut': 0,
    Coconut: 0,
    Hazelnut: 0,
    Peanut: 0,

    'Nut Mix 2': 0,
    Cashew: 0,
    Pecan: 0,
    Pistachio: 0,
    Walnut: 0,

    'Shellfish Mix': 0,
    Clam: 0,
    Crab: 0,
    Oyster: 0,
    Scallops: 0,
    Shrimp: 0,
    Lobster: 0,

    // No Group
    Egg: 0,
    Milk: 0,
    Soy: 0,
    Wheat: 0,
  },
  // 'Fish Mix': {
  //   Almond: 0,
  //   'Brazil nut': 0,
  //   Clam: 0,
  //   Cashew: 0,
  //   Coconut: 0,
  //   Codfish: 0.3,
  //   Crab: 0,
  //   Egg: 0,
  //   Fish: 0,
  //   Flounder: 0.3,
  //   Hazelnut: 0,
  //   Mackerel: 0.3,
  //   Milk: 0,
  //   Oyster: 0,
  //   Peanut: 0,
  //   Pecan: 0,
  //   Pistachio: 0,
  //   Scallops: 0,
  //   Shrimp: 0,
  //   Soy: 0,
  //   Tuna: 0.3,
  //   Walnut: 0,
  //   Wheat: 0,
  // },
  // 'Nut Mix 1': {
  //   Almond: 0.3,
  //   'Brazil nut': 0.3,
  //   Clam: 0,
  //   Cashew: 0,
  //   Coconut: 0.3,
  //   Codfish: 0,
  //   Crab: 0,
  //   Egg: 0,
  //   Fish: 0,
  //   Flounder: 0,
  //   Hazelnut: 0.3,
  //   Mackerel: 0,
  //   Milk: 0,
  //   Oyster: 0,
  //   Peanut: 0.3,
  //   Pecan: 0,
  //   Pistachio: 0,
  //   Scallops: 0,
  //   Shrimp: 0,
  //   Soy: 0,
  //   Tuna: 0,
  //   Walnut: 0,
  //   Wheat: 0,
  // },
  // 'Nut Mix 2': {
  //   Almond: 0,
  //   'Brazil nut': 0,
  //   Clam: 0,
  //   Cashew: 0.4,
  //   Coconut: 0,
  //   Codfish: 0,
  //   Crab: 0,
  //   Egg: 0,
  //   Fish: 0,
  //   Flounder: 0,
  //   Hazelnut: 0,
  //   Mackerel: 0,
  //   Milk: 0,
  //   Oyster: 0,
  //   Peanut: 0,
  //   Pecan: 0.4,
  //   Pistachio: 0.4,
  //   Scallops: 0,
  //   Shrimp: 0,
  //   Soy: 0,
  //   Tuna: 0,
  //   Walnut: 0.4,
  //   Wheat: 0,
  // },
  // 'Shellfish Mix': {
  //   Almond: 0,
  //   'Brazil nut': 0,
  //   Clam: 0.4,
  //   Cashew: 0,
  //   Coconut: 0,
  //   Codfish: 0,
  //   Crab: 0.4,
  //   Egg: 0,
  //   Fish: 0,
  //   Flounder: 0,
  //   Hazelnut: 0,
  //   Mackerel: 0,
  //   Milk: 0,
  //   Oyster: 0.4,
  //   Peanut: 0,
  //   Pecan: 0,
  //   Pistachio: 0,
  //   Scallops: 0.4,
  //   Shrimp: 0.4,
  //   Soy: 0,
  //   Tuna: 0,
  //   Walnut: 0,
  //   Wheat: 0,
  // },
};

export type EnvironmentalCommonFormulationsKey = keyof typeof environmentalCommonFormulations;
export type FoodCommonFormulationsKey = keyof typeof foodCommonFormulations;
