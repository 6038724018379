export enum SLITPharmacyEnum {
  AC = 'ac',
  BW = 'bw',
  IC = 'ic',
  RPC2B = 'rc2b',
}

/** @deprecated use SLITPharmacyEnumKey instead */
export type SLITPharmacyEnumType = keyof typeof SLITPharmacyEnum;

export type SLITPharmacyEnumKey = keyof typeof SLITPharmacyEnum;
export type SLITPharmacyEnumValue = `${SLITPharmacyEnum}`;
